import React, { useEffect, useRef } from "react";
import { getStyles } from "../../../assets/js/utils/functions";
import { FrontPagesCustomers, FrontPagesNavigation } from "../commons";
import classNames from "classnames";
import gsap, { Power1 } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

interface HeaderProps {
  addon?: React.ReactElement;
  isHomePage?: boolean;
  followText: string;
  ctas: React.ReactElement;
  heroContent: React.ReactElement;
  mobileHero?: boolean;
  className?: string;
}

const FrontPagesHeader: React.FC<HeaderProps> = ({
  addon,
  isHomePage = false,
  followText,
  ctas,
  heroContent,
  children,
  mobileHero = true,
  className,
}) => {
  return (
    <header className="section-spacing">
      <div className={`front-header ${className ?? ""}`}>
        <FrontPagesNavigation />
        <div className="body-padding">
          <div className="mt-8 sm:11.25 md:mt-15 flex flex-col items-center container">
            <div className="flex flex-col items-center">
              {addon && addon}
              <h1
                className={classNames("font-light text-center text-black mt-5", {
                  "heading-one": isHomePage,
                  "heading-two": !isHomePage,
                })}
              >
                {children}
              </h1>
              <p
                className={classNames(
                  "text-black-secondary text-center follow-text font-normal max-w-[390px] sm:max-w-[520px] lg:max-w-[570px]  xl:max-w-[620px] mt-3.75 sm:mt-5 slide-text-in",
                  { "follow-text": isHomePage, "hero-text": !isHomePage }
                )}
                style={getStyles({ "--delay": "0.6s" })}
              >
                {followText}
              </p>

              <div
                className="mt-8 flex items-center flex-col sm:flex-row space-y-3.75 sm:space-x-5 sm:space-y-0 mb-11.25 sm:mb-14 md:mb-18 lg:mb-20 slide-text-in"
                style={getStyles({ "--delay": "0.75s" })}
              >
                {ctas}
              </div>
            </div>
            <figure
              className={`w-full max-w-[1150px] mx-auto header-video relative z-[9999] ${mobileHero ? "mobile" : ""}`}
            >
              {heroContent}
            </figure>
          </div>
        </div>
      </div>
      <FrontPagesCustomers />
    </header>
  );
};

export default FrontPagesHeader;
